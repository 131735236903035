<template>
  <div class="page-container">
    <div class="page-route-select">
      <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
        Home
      </router-link>
      <span class="material-icons page-route-select-icon">play_arrow</span>
      <div class="page-route-select-text">
        Produtos Padrão
      </div>
    </div>
    <div class="page-title">Produtos Padrão</div>

    <div class="products-page__container">
      <div class="products-page__left-wrapper">
        <div>
          <div v-if="!productIsInUse" @click="getInUsageProductsList" class="lastbuydate_wrapper">
            <label class="products-page__checkbox-wrapper">
              <span class="lastbuydate_title">Apenas itens em uso</span>
              <input type="checkbox">
              <span class="checkmark"></span>
            </label>
          </div>
          <div v-if="productIsInUse" @click="removeInUsageProductsList" class="lastbuydate_wrapper">
            <label class="products-page__checkbox-wrapper">
              <span class="lastbuydate_title">Apenas itens em uso</span>
              <input type="checkbox" checked>
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <div>
          <p class="food-service-input-text">Produtos mais requisitados</p>
          <div class="food-service-op-container">
            <img v-on:click="requestSize = op.value" :class="getOpClass(op)" class="food-service-op-icon" :src="op.img" v-for="op in requestList" :key="op.value">
          </div>
        </div>
      </div>
      <div class="products-page__right-wrapper">
        <Button class="action-buttons" :iconName="'cancel'" title="Limpar Filtros" :text="'Limpar Filtros'"
          :action="clearFilters" />
        <Button class="action-buttons" :iconName="'add_circle'" title="Adicionar Produto" :text="'Adicionar Produto'"
          :action="() => { edit({}) }" />
      </div>
    </div>

    <div class="products-page__container">
      <StandardInput title="Período da requisição" :value="selectDate" :action="value => selectDate = value" :list="dates"
        class="products-page__selects" :type="'select'" />
      <StandardInput :placeholder="'Digite o produto'" :title="'Produto'" :value="filters.name" :action="setProductValue"
        class="products-page__selects" :type="'text'" />

      <!-- <StandardInput :placeholder="'Selecione a categoria'"
                     :title="'Categoria'"
                     :action="setCategoryValue"
                     class="products-page__selects"
                     :list="arraySelectCategory"
                     :type="'select'"/> -->
      <StandardInput :placeholder="'Selecione o estado'" :title="'Estado'" :value="selectState" :action="setStateValue"
        class="products-page__selects" :list="states" :type="'select'" />
      <StandardInputListFiltered :placeholder="'Digite a cidade'" :title="'Cidade'" selectWidth="23vw"
        :action="setCityValues" :value="selectCity" :list="citiesShow" class="products-page__selects" :type="'select'" />
    </div>

    <div class="products-page-categories-title">Categorias</div>

    <div class="mobile-only-arrows">
      <span role="button" v-on:click="moveCategoryDow()"
        class="material-icons-outlined product-page-categories-arrows">arrow_back_ios</span>
      <span role="button" v-on:click="moveCategoryUp()"
        class="material-icons-outlined product-page-categories-arrows">arrow_forward_ios</span>
    </div>

    <div class="product-page-categorie-list">
      <span role="button" v-on:click="moveCategoryDow()"
        class="material-icons-outlined desktop-only product-page-categories-arrows">arrow_back_ios</span>
      <img class="product-page-categorie-card" :class="getActiveCategorie(categorie.id)"
        @click="setCategoryValue(categorie.id)" v-for="(categorie, idx) in categoriesIcons" :key="idx"
        :src="categorie.image">
      <span role="button" v-on:click="moveCategoryUp()"
        class="material-icons-outlined desktop-only product-page-categories-arrows">arrow_forward_ios</span>
    </div>

    <cc-loader-full v-show="isLoading" />
    <div v-show="!isLoading" class="product-page-products-card-container">
      <div class="product-page-products-card" v-for="(product, idx) in products" :key="idx">
        <div class="product-page-products-card-img-container">
          <img v-if="product.pro_imagem" class="product-page-products-card-img" :src="product.pro_imagem" alt="">
          <img v-else class="product-page-products-card-img" src="@/assets/newLogo.jpeg" alt=""/></img>
        </div>
        <div class="product-page-products-card-title" :title="product.name">{{ product.name }}</div>
        <div class="product-page-products-card-info" style="display: flex;">
          <div> Cod: {{ product.ean }}</div>
          <div style="margin-left: 20%;"> Id: {{ product.id }}</div>
        </div>
        <div class="product-page-products-card-info">
          <img src="@/assets/package.svg" style="margin-right: 5%; width: 1.2em;" alt="">
          {{ product.is_generic ? product.purchase_unit : product.packing }}
        </div>
        <div class="product-page-actions-container">
          <div>Ações:</div>
          <span class="material-icons-outlined edit-icon" title="Editar" @click="edit(product)">edit</span>
          <span class="material-icons-outlined delete-icon" title="Remover" @click="delete_product(product)">delete</span>
        </div>
        <div class="product-page-quantity-container">
          Qtd. Requisição:
          <div style="background: rgba(255, 113, 16, 0.15); border-radius: 8px; padding: 3px 1vw;">{{ product.emUso >= 1 ?
            product.emUso : '--' }}</div>
        </div>
        <div class="product-page-buttons-container">
          <div class="product-page-buttons-brand product-page-button" @click="open_brands(product, 'brand')">Marcas</div>
          <div class="product-page-buttons-price product-page-button" @click="open_brands(product, 'price')">Preços</div>
        </div>
        <div class="product-page-buttons-supliers" @click="open_brands(product, 'supliers')">Fornecedores</div>
      </div>
    </div>

    <tr style="display: flex !important; padding-bottom: 5vh;" v-show="total_items > items_by_page_value">
      <td style="margin: 0 auto;" colspan="5">
        <Paginacao classes="orange justify-end" :items_by_page_value="items_by_page_value" :total="total_items"
          :page="page" v-on:change="list($event)">
        </Paginacao>
      </td>
    </tr>

    <!-- <div v-show="!isLoading" class="page-table-container">
    <div>
      <div class="page-table-header">
        <div class="page-table-header-text img-width"></div>
        <div class="page-table-header-text ean-code-width">Código/Pro ID</div>
        <div class="page-table-header-text description-width">Descrição</div>
        <div class="page-table-header-text package-width">Embalagem</div>
        <div class="page-table-header-text toggle-width">Destaque</div>
        <div class="page-table-header-text status-width">Status</div>
        <div class="page-table-header-text status-width">Qtd. de Requisição</div>
        <div class="page-table-header-text actions-width text-center">Ações</div>
      </div>
      <div v-for="(product, idx) in products"
           :key="'prod-' + product.id"
           :class="getSpecialBackground(idx)">
        <div class="page-table-row">
          <div class="page-table-desc-column img-width">
            <div @click="openFullScreenImageModal(product)"
                 class="upload-img-icon"
                 :style="getProductImage(product)">
            </div>
          </div>
          <div class="page-table-desc-column ean-code-width"><div class="page-table-header-mobile">Código</div>{{ product.ean }} <br> {{ product.id }}</div>
          <div class="page-table-desc-column description-width"><div class="page-table-header-mobile">Descrição</div>{{ product.name }}</div>
          <div class="page-table-desc-column package-width"><div class="page-table-header-mobile">Embalagem</div>{{ product.is_generic ? product.purchase_unit : product.packing }}</div>
          <div class="page-table-desc-column toggle-width">
            <div class="page-table-header-mobile">Destaque</div>
            <toggle-button v-model="product.is_highlighted"
                           @change="({ value }) => toggle_highlight(value, product)"
                           :color="'#ff7110'"
                           :sync="true"
                           :height="30"
                           :width="70"/>
          </div>
          <div class="page-table-desc-column status-width"
               :class="{ 'text-in-use': product.emUso }">
            <div class="page-table-header-mobile">Status</div>
            <span v-if="product.emUso >= 1" class="material-icons-outlined">check_circle</span>
            <span v-else >--</span>
          </div>
          <div class="page-table-desc-column status-width">
            <div class="page-table-header-mobile">Qtd. de Requisição</div>
            {{ product.emUso >= 1 ? product.emUso : '--' }}
          </div>
          <div class="page-table-desc-column actions-width text-center">
            <div class="page-table-header-mobile">Ações</div>
            <div style="display: flex; align-items: center;">
              <span class="material-icons-outlined edit-icon" title="Editar" @click="edit(product)">
                edit
              </span>
              <img class="boxes-icon" v-if="product.is_generic" title="Marcas" @click="open_brands(product)" src="@/assets/custom-boxes-icon.png">
              <span class="material-icons-outlined delete-icon" title="Remover" @click="delete_product(product)">
                delete
              </span>
            </div>

          </div>
        </div>
      </div>
      <tr style="display: flex; !important" v-show="total_items > items_by_page_value">
        <td style="margin: 0 auto;" colspan="5">
          <Paginacao classes="orange justify-end"
                     :items_by_page_value="items_by_page_value"
                     :total="total_items"
                     :page="page"
                     v-on:change="list($event)">
          </Paginacao>
        </td>
      </tr>
    </div>
  </div> -->
    <FullScreenImage v-if="showFullScreenImageModal" :closeDialog="closeFullScreenImageModal" :imageInfo="imageInfo" />
    <cc-product-brands-modal v-if="is_brands_modal_open && current_product && showProductBrandsModal"
      :closeDialog="closeProductBrandsModal" :product="current_product" :product_pattern="product_pattern"
      :initTab="brandModalTab" @close="is_brands_modal_open = false; current_product = null" />
    <edit-product-modal v-if="is_edit_modal_open && current_product && showEditProductModal"
      :closeDialog="closeEditProductModal" :product="current_product" :categories="categories" @reload="reload"
      @close="is_edit_modal_open = false; current_product = null" />
  </div>
  <!-- <div class="monitors">
        <div class="body">
            <div class="container-fluid mb-5">
                <div class="row py-3 d-flex justify-content-between">
                    <div class="col">
                        <label for="" class="label-control">Nome do produto</label>
                        <cc-search v-model="filters.name" />
                    </div>
                    <div class="col">
                        <label for="" class="label-control">Selecione uma categoria</label>
                        <cc-select-v2 :tracker="'id'" :text="'name'" :options="categories" v-model="filters.category"></cc-select-v2>
                    </div>
                    <div class="d-flex col-5 justify-content-end align-items-end buttons">
                        <cc-button :icon="'far fa-trash-alt'" @click="filters = {}" :content="'Limpar Filtros'"  />
                        <cc-button :content="'Adicionar Produto'" :icon="'fa fa-plus'" :classes="'success'" @click="edit({})" />
                    </div>
                </div>
                <div class="row">
                    <div class="col col-12 p-4 shadow">
                        <no-data-available v-if="products.length == 0" :text="'Sua lista de produtos está vazia'" />
                        <table v-show="products.length > 0" class="table">
                            <thead>
                                <tr>
                                    <th class="pl-4">Código</th>
                                    <th>Descrição</th>
                                    <th>Embalagem</th>
                                    <th>Destaque?</th>
                                    <th class="controls"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="product in products" >
                                    <tr :key="'prod-' + product.id">
                                        <td class="pl-4">{{ product.ean }}</td>
                                        <td>{{ product.name }}</td>
                                        <td>{{ product.is_generic ? product.purchase_unit : product.packing }}</td>
                                        <td>
                                            <toggle-button
                                                v-model="product.is_highlighted"
                                                @change="({ value }) => toggle_highlight(value, product)"
                                                :color="'#ff7110'"
                                                :sync="true"
                                                :height="30"/>
                                        </td>
                                        <td class="actions text-right">
                                            <i class="fa fa-pencil fa-lg"
                                               title="Editar"
                                               @click="edit(product)"></i>
                                            <i class="fas fa-boxes"
                                               v-if="product.is_generic"
                                               title="Marcas"
                                               @click="open_brands(product)"></i>
                                            <i class="fa fa-trash fa-lg"
                                               title="Remover"
                                               @click="delete_product(product)"></i>
                                        </td>
                                    </tr>
                                </template>
                                <tr v-show="total_items > items_by_page_value">
                                    <td class="text-center" colspan="5">
                                        <Paginacao
                                            classes="orange justify-end"
                                            :items_by_page_value="items_by_page_value"
                                            :total="total_items"
                                            :page="page"
                                            v-on:change="list($event)"
                                        ></Paginacao>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <cc-product-brands-modal
            v-if="is_brands_modal_open && current_product"
            :product="current_product"
            :product_pattern="product_pattern"
            @close="is_brands_modal_open = false;current_product = null" />
        <cc-client-categories v-if="manage_categories" @close="manage_categories = false" />
        <edit-product-modal
            v-if="is_edit_modal_open && current_product"
            :product="current_product"
            :categories="categories"
            @reload="list"
            @close="is_edit_modal_open = false;current_product = null" />
    </div> -->
</template>

<script>
import Paginacao from "@/components/cliente/base-components/Pagination";
import ProductBrandsModal from "@/modals/product-brands/product-brands.modal";
import ProductModal from "@/modals/new-product/new-product.modal";
import ManageCategoriesModal from "@/modals/client/categories/manage-categories.modal";
import ProductService from '@/services/v3/management/product.service';
import CategoryService from '@/services/v3/management/category.service';
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import ErrorHandlerService from "@/services/ErrorHandlerService";
import { typingRemoteRequestControlMixin } from "@/mixins/typing-remote-request-controls.mixin";
import { Datetime } from 'vue-datetime';
import Button from '@/components/ui/buttons/StandardButton.vue'
import StandardInput from '@/components/ui/inputs/StandardInput.vue'
import StandardInputListFiltered from '@/components/ui/inputs/StandardInputListFiltered.vue'
import UserService from "@/services/v1/user.service";
import FullScreenImage from "@/components/ui/images/FullScreenImage.vue";

export default {
  mixins: [loaderMixin, typingRemoteRequestControlMixin],
  components: {
    Paginacao,
    'edit-product-modal': ProductModal,
    'cc-product-brands-modal': ProductBrandsModal,
    'cc-client-categories': ManageCategoriesModal,
    Datetime,
    Button,
    StandardInput,
    StandardInputListFiltered,
    FullScreenImage
  },
  data() {
    return {
      productIsInUse: false,
      brandModalTab: undefined,
      productFilteredInUse: null,
      showProductBrandsModal: false,
      showEditProductModal: false,
      arraySelectProduct: [],
      arraySelectCategory: [],
      minDate: moment().toDate().toISOString(),
      states: [],
      cities: [],
      citiesShow: [],
      selectState: undefined,
      selectCity: undefined,
      selectDate: undefined,
      loading: false,
      isLoading: false,
      showTable: false,
      isLastBuyListChecked: false,
      page: 1,
      products: [],
      requestSize: undefined,
      filters: {
        category: { id: null }
      },
      categories: [],
      items_by_page_value: 30,
      total_items: 0,
      brands: null,
      current_product: null,
      manage_categories: false,
      svc: new ProductService(),
      cat_svc: new CategoryService(),
      user_svc: new UserService(),
      show_add_product_modal: false,
      is_brands_modal_open: false,
      is_edit_modal_open: false,
      product_pattern: true,
      options: [
        {
          label: "Novo Produto",
          action: () => { this.show_add_product_modal = true }
        }
      ],
      breadcrumbs: [
        {
          name: "Produtos Padrão",
          route: 'products'
        }
      ],
      showFullScreenImageModal: false,
      imageInfo: undefined,
      productIsMostRequested: false,
      productFilteredQuantity: null,
      productFilteredMostRequested: null,
      categoriesIcons: [],
      categoriesIconsList: [
        { image: require('@/assets/alimenticioImage.svg'), id: 1 },
        { image: require('@/assets/friosImage.svg'), id: 2 },
        { image: require('@/assets/proteinasImage.svg'), id: 3 },
        { image: require('@/assets/bebidasImage.svg'), id: 4 },
        { image: require('@/assets/perfumesImage.svg'), id: 5 },
        { image: require('@/assets/limpezaImage.svg'), id: 6 },
        { image: require('@/assets/bazarImage.svg'), id: 7 },
        { image: require('@/assets/descartaveisImage.svg'), id: 8 },
        { image: require('@/assets/epiImage.svg'), id: 9 },
        { image: require('@/assets/matImage.svg'), id: 10 },
        { image: require('@/assets/hortiImage.svg'), id: 11 },
        { image: require('@/assets/petImage.svg'), id: 12 },
        { image: require('@/assets/cotImage.svg'), id: 13 },
      ],
      requestList: [
        {value: undefined, img: require('@/assets/Icone__todas_.svg'), class: 'food-service-op-all', activeClass: 'food-service-op-all-active'},
        {value: 100, img: require('@/assets/Icone_100.svg'), class: 'food-service-op-100', activeClass: 'food-service-op-100-active' },
        {value: 200, img: require('@/assets/Icone_200.svg'), class: 'food-service-op-200', activeClass: 'food-service-op-200-active' },
        {value: 300, img: require('@/assets/Icone_300.svg'), class: 'food-service-op-300', activeClass: 'food-service-op-300-active' },
      ],
      categoriesIconsNumbers: [0, 1, 2, 3, 4, 5],
      dates: [
        { text: 'Ultimos 30 Dias', value: 30 },
        { text: 'Ultimos 60 Dias', value: 60 },
        { text: 'Ultimos 90 Dias', value: 90 },
        { text: 'Ultimos 180 Dias', value: 180 },
      ]
    }
  },
  /* watch: {
      filters: {
          handler: function() {
              this.list(1)
          },
          deep: true
      }
  }, */
  watch: {
    selectDate() {
      this.list(this.page)
    },
    requestSize(){
      this.list(this.page)
    }
  },
  methods: {
    getOpClass(op) {
      if (op.value == this.requestSize) return op.activeClass
      return op.class
    },
    moveCategoryUp() {
      if (this.categoriesIconsNumbers.at(-1) == this.categoriesIconsList.length - 1) return
      for (let index = 0; index < this.categoriesIconsNumbers.length; index++) this.categoriesIconsNumbers[index]++
      this.categoriesIcons = []
      this.categoriesIconsNumbers.forEach(element => this.categoriesIcons.push(this.categoriesIconsList[element]))
    },
    moveCategoryDow() {
      if (this.categoriesIconsNumbers[0] == 0) return
      for (let index = 0; index < this.categoriesIconsNumbers.length; index++) this.categoriesIconsNumbers[index]--
      this.categoriesIcons = []
      this.categoriesIconsNumbers.forEach(element => this.categoriesIcons.push(this.categoriesIconsList[element]))
    },
    getProductImage(product) {
      return `background-image: url(${product.pro_imagem}?lastmod=${Date.now()})`
    },
    closeFullScreenImageModal: function () {
      this.showFullScreenImageModal = false;
    },
    openFullScreenImageModal(product) {
      if (product.pro_imagem != "https://club-storage.sfo3.digitaloceanspaces.com/kyojin/products/logos/") {
        this.showFullScreenImageModal = true
        this.imageInfo = product.pro_imagem
      }
    },
    setCityValues(value) {
      this.selectCity = value
      this.list(this.page)
    },
    getActiveCategorie(category) {
      if (category == this.filters.category.id) return 'product-page-categorie-card-active'
    },
    setStateValue(value) {
      this.selectState = value
      this.list(this.page)
      this.filterCities()
    },
    setProductValue(value) {
      this.filters.name = value
      this.list(this.page)
    },
    setCategoryValue(value) {
      if (this.filters.category.id == value) value = null
      this.filters.category = { id: value }
      this.list(this.page)
    },
    closeProductBrandsModal: function () {
      this.showProductBrandsModal = false;
    },
    closeEditProductModal: function () {
      this.showEditProductModal = false;
    },
    clearFilters() {
      this.filters = {
        category: { id: null }
      },
      this.selectDate = ''
      this.selectCity = ''
      this.selectState = ''
      this.list(this.page)
    },
    reload() {
      this.list(this.page)
    },
    getSpecialBackground(idx) { if (idx % 2 != 0) return 'page-table-line-special' },
    getMostRequestedProductsList() {
      this.productIsMostRequested = true
      this.productFilteredQuantity = 'emUso'
      this.productFilteredMostRequested = 'desc'

      setTimeout(() => {
        this.list()
      }, 400);
    },
    removeMostRequestedProductsList() {
      this.productIsMostRequested = false
      this.productFilteredQuantity = null
      this.productFilteredMostRequested = null

      setTimeout(() => {
        this.list()
      }, 400);
    },
    getInUsageProductsList() {
      this.productIsInUse = true
      this.productFilteredInUse = 1
      setTimeout(() => {
        this.list()
      }, 400);
    },
    removeInUsageProductsList() {
      this.productFilteredInUse = null
      this.productIsInUse = false
      setTimeout(() => {
        this.list()
      }, 400);
    },
    list(page = this.page, inUse = this.productFilteredInUse) {
      this.isLoading = true
      this.cancelSearch(() => { this.loading = true });
      if (this.selectDate) {
        var day = new Date();
        day.setDate(day.getDate()-this.selectDate);
      } else {var day = undefined}

      const params = {
        name: this.filters.name,
        category_id: this.filters.category?.id,
        city: this.selectCity ? this.selectCity.cid_nome : '',
        fu: this.selectState ? this.selectState.est_sigla : '',
        start_at: day,
        page: page,
        filter: this.productFilteredInUse,
        sort_by: this.productFilteredQuantity,
        sort_order: this.productFilteredMostRequested,
      };
      if (this.requestSize) {
        params.sort_order = 'desc'
        params.limit = this.requestSize
      }
      this.svc.list(params, this.cancelSource.token).then(response => response.data).then(data => {
        this.isLoading = false
        if(data.data) {
          this.products = data.data
          this.total_items = data.meta.total
        } else {
          this.products = data
          this.total_items = 0
        }

      }).catch(error => {
        ErrorHandlerService.handle(error, this.$store);
      });
    },
    list_categories() {
      this.cat_svc.list().then((response) => response.data).then((data) => {
        this.categories = data
        data.forEach(element => {
          this.arraySelectCategory.push({
            text: element.name,
            value: element
          })
        })
      })
    },
    list_address() {
      this.user_svc.region_data().then(response => response.data).then(data => {
        this.cities = data.cidades.map(c => ({ value: c, text: c.cid_nome }))
        this.states = data.estados.map(c => ({ value: c, text: c.est_sigla }))
        this.filterCities()
      })
    },
    filterCities() {
      if (this.selectState) {
        this.citiesShow = []
        this.cities.forEach(element => {
          if (element.value.est_id == this.selectState.est_id) this.citiesShow.push(element)
        })
      }
      else this.citiesShow = this.cities
    },
    edit(product) {
      this.current_product = product
      this.is_edit_modal_open = true
      this.showEditProductModal = true
    },
    open_brands(product, tab) {
      // this.is_brands_modal_open = true
      // this.current_product = product
      // this.brandModalTab = tab
      // this.showProductBrandsModal = true;
      this.$router.push('/produtos/marcas')
      this.$store.commit('setProductBrands', {
        product: product,
        tab: tab,
        states: this.states,
        cities: this.cities
      })
    },
    delete_product(product) {
      this.confirm_action({
        message: "Confirma a remoção do produto?",
        subtitle: `Produto será excluído da base padrão!`,
        callback: () => {
          this.svc.delete(product.id)
            .then(() => {
              this.list(1);
              this.present_success("Excluído!", "Produto excluído com sucesso");
            }).catch(error => {
              ErrorHandlerService.handle(error, this.$store);
            })
        }
      })
    },
  },
  async mounted() {
    this.list()
    // this.list_categories()
    this.categoriesIconsNumbers.forEach(element => this.categoriesIcons.push(this.categoriesIconsList[element]))
    this.list_address()
    this.$store.dispatch('set_breadcrumbs', this.breadcrumbs);
  }
}
</script>

<style lang="scss" scoped>
@import './products.page';

.page-container {
  font-size: 1vw;
  margin-right: 4em;
}

.page-route-select {
  display: flex;
  align-items: center;
}

.page-route-select-text {
  font-weight: 400;
  font-size: 1.36em;
  line-height: 17px;
  color: #ABABAB;
  margin-right: 20px;
  cursor: pointer;
}

.page-route-select-icon {
  color:  var(--primary-color);
  margin-right: 20px;
}

.datetime-input {
  border: 0;
}

.page-filters__right-title-wrapper {
  display: flex;
  align-items: center;
}

.products-page__datetime-title {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.15px;
  color: #898989;
}

.page-filters__right-select-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  padding: 4px 0;
}

.date-icon {
  color: var(--primary-color);
  padding: 8px 10px;
  border-radius: 0px 8px 8px 0px;
  position: relative;
  left: -6px;
}

.page-title {
  margin-top: 45px;
  font-weight: 700;
  font-size: 2.72em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #605F5F;
}

.products-page__container {
  display: flex;
  justify-content: space-between;
  margin: 3em 0em;
}

.products-page__left-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2vw;
}

.products-page-categories-title {
  color: #808080;
  font-size: 1.7em;
}

.product-page-categorie-card {
  border-radius: 12px;
  width: 13vw;
  cursor: pointer;
}

.product-page-categorie-card:hover {
  outline: 0.3vw solid lightsalmon;
}

.product-page-categorie-card-active {
  outline: 0.3vw solid lightsalmon;
}

.product-page-categorie-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-page-actions-container {
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #605F5F;
  margin-top: 1vh;
}

.product-page-quantity-container {
  display: flex;
  gap: 1vw;
  margin-top: 0.5vh;
  align-items: flex-end;
  color: var(--primary-color);
  font-size: 1.1em;
}

.product-page-buttons-container {
  display: flex;
  justify-content: space-between;
  margin-top: 2vh;
}

.product-page-button {
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 1.2em;
  text-align: center;
  width: 45%;
  cursor: pointer;
}

.product-page-buttons-brand {
  background: var(--primary-color);
}

.product-page-buttons-brand:hover {
  color: var(--primary-color);
  background: #FFEADB;
}

.product-page-buttons-price {
  background: #278E3F;
}

.product-page-buttons-price:hover {
  background: rgba(42, 205, 114, 0.1);
  color: #30AA4C;
}

.product-page-buttons-supliers {
  background: #17A2B8;
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 1.2em;
  text-align: center;
  margin-top: 1.5vh;
  cursor: pointer;
}

.product-page-buttons-supliers:hover {
  color: #17A2B8;
  background: rgba(23, 162, 184, 0.1);
}
.food-service-op-container{
  display: flex;
  gap: 9vw;
}
.food-service-op-icon{
  width: 8vw;
  border-radius: 50%;
  cursor: pointer;
}
.food-service-op-all:hover{
  outline: var(--primary-color) 3px solid;
}
.food-service-op-100:hover{
  outline: lightblue 3px solid;
}
.food-service-op-200:hover{
  outline: lightgreen 3px solid;
}
.food-service-op-300:hover{
  outline: rgb(97, 97, 250) 3px solid;
}
.food-service-op-all-active{
  outline: var(--primary-color) 3px solid;
  cursor: default;
}
.food-service-op-100-active{
  outline: var(--primary-color) 3px solid;
  cursor: default;
}
.food-service-op-200-active{
  outline: var(--primary-color) 3px solid;
  cursor: default;
}
.food-service-op-300-active{
  outline: var(--primary-color) 3px solid;
  cursor: default;
}
.food-service-input-text{
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: var(--primary-color);
  margin-bottom: 2vh;
}
.product-page-categories-arrows {
  font-size: 2em;
  color: var(--primary-color);
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mobile-only-arrows {
  display: none;
}

.products-page__right-wrapper {
  display: flex;
  align-items: flex-end;
  gap: 2vw;
}

.lastbuydate_wrapper {
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.products-page__checkbox-wrapper {
  display: flex;
}

/* The container */
.products-page__checkbox-wrapper {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 1.7em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #898989;
}

/* Hide the browser's default checkbox */
.products-page__checkbox-wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 2.5px solid #ff7110;
  border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.products-page__checkbox-wrapper:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.products-page__checkbox-wrapper input:checked~.checkmark {
  background-color: var(--primary-color);
  border: 1px solid #ff7110;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.products-page__checkbox-wrapper input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.products-page__checkbox-wrapper .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.page-table-header {
  background: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  font-weight: 400;
  color: #605F5F;
  display: flex;
  padding: 1em 0em;
}

.page-table-header-text {
  padding-left: 10px;
  font-weight: 600;
  font-size: 1.19em;
  color: #505050;
}

.page-table-row {
  display: flex;
  font-weight: 300;
  font-size: 1.0em;
  color: #605F5F;
}

.page-table-header-mobile {
  display: none;
}

.page-table-desc-column {
  padding: 0.5em 0.7em;
}

.page-table-line-special {
  background: #F7F7F7;
}

.ean-code-width {
  width: 12%;
}

.description-width {
  width: 36%;
}

.package-width {
  width: 20%;
}

.toggle-width {
  width: 16%;
}

.status-width {
  width: 10%;
}

.img-width {
  width: 8%;
}

.actions-width {
  display: flex;
  align-items: center;
  width: 10%;
  padding: 0em;
}

.edit-icon {
  color: var(--primary-color);
  margin-right: 5px;
  cursor: pointer;
}

.boxes-icon {
  margin-right: 5px;
  width: 1.5em;
  cursor: pointer;
}

.delete-icon {
  color: #CB595B;
  cursor: pointer;
}

.edit-product__input-title {
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
  font-size: 1.6em;
}

.products-page__product-input {
  border: 1px solid #c2c2c3;
  font-size: 17px;
  padding: 11px;
  border-radius: 5px;
  width: 30vw;
}

.products-page__selects {
  width: 22%;
}

.products-page__product-input:focus {
  border: 1px solid #c2c2c3 !important;
}

.edit-product__input-title-wrapper {
  padding-bottom: 5px;
}

.category-input-width {
  width: 30vw !important;
}

.page-selected-filters-containear {
  display: flex;
  margin-bottom: 2em;
}

.page-selected-filters {
  background: #FCF9F7;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.page-selected-filters-text {
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: var(--primary-color);
  margin-right: 5px;
}

.page-selected-filters-icon {
  color: var(--primary-color);
  cursor: pointer;
}

.products-page__product-input-container {
  display: flex;
  align-items: center;
}

.search-icon {
  position: relative;
  left: -35px;
  color: #606060;
}

.text-center {
  justify-content: center;
}

.text-in-use {
  color: #187C3B;
  font-weight: 600;
  text-transform: uppercase;
}

.upload-img-icon {
  width: 64px;
  height: 64px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  cursor: pointer;
}

.image-upload>input {
  display: none;
}

.image-upload img {
  width: 40px;
  cursor: pointer;
}

.edit-icon {
  margin-left: 10px;
  cursor: pointer;
  color: #505050;
}

.edit-image-icon {
  cursor: pointer;
  color: #505050;
}

.remove-icon {
  color: #CB595B;
  margin-left: 10px;
  cursor: pointer;
}

.product-page-products-card-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  margin-top: 5vh;
}

.product-page-products-card {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: 90%;
  margin-bottom: 3vh;
  padding: 2vh 2vw;
}

.product-page-products-card-img-container {
  height: 15vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-page-products-card-img {
  max-height: 100%;
  max-width: 100%;
}

.product-page-products-card-title {
  display: -webkit-box;
  width: 16vw;
  margin-top: 2vh;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: 600;
  color: #605F5F;
}

.product-page-products-card-info {
  display: flex;
  color: #6A6D71;
  font-size: 1.1em;
}

@media only screen and (max-width: 1000px) {
  .page-table-container {
    font-size: 1.3em;
  }

  .page-container {
    font-size: 1.5vw;
    margin-right: 5vw;
  }

  .page-table-header {
    display: none;
  }

  .page-table-row {
    flex-direction: column;
  }

  .ean-code-width {
    width: 100%;
  }

  .description-width {
    width: 100%;
  }

  .package-width {
    width: 100%;
  }

  .toggle-width {
    width: 100%;
  }

  .status-width {
    width: 100%;
  }

  .actions-width {
    width: 100%;
  }

  .page-table-desc-column {
    padding-left: 0;
    display: flex;
    justify-content: space-between;
    text-align: right;
  }

  .page-table-header-mobile {
    font-weight: 600;
    font-size: 1.1em;
    color: #505050;
    text-transform: none;
    display: block;
  }
}

@media only screen and (max-width: 800px) {
  .mobile-only-arrows {
    display: flex;
    justify-content: space-evenly;
    margin: 0 0 5vh 0;
  }

  .desktop-only {
    display: none;
  }

  .page-container {
    font-size: 2vw;
  }

  .products-page__container {
    flex-direction: column;
    gap: 3vh;
  }

  .products-page__right-wrapper {
    justify-content: space-between;
    gap: 0;
  }

  .action-buttons {
    width: 45%;
  }

  .products-page__selects {
    width: 100%;
  }

  .product-page-products-card-container {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .product-page-categorie-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .product-page-categorie-card {
    width: 90%;
    margin-bottom: 2vh;
  }

  .product-page-products-card-title {
    width: 25vw;
  }
}

@media only screen and (max-width: 600px) {
  .page-container {
    font-size: 2.5vw;
  }

  .products-page__left-wrapper {
    flex-direction: column;
  }

  .lastbuydate_wrapper {
    margin-top: 0;
  }
}

@media only screen and (max-width: 450px) {
  .page-container {
    font-size: 3.5vw;
  }

  .product-page-products-card-container {
    grid-template-columns: 1fr 1fr;
  }

  .product-page-categorie-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .product-page-products-card-title {
    width: 45vw;
  }
}</style>
